import type { MetaFunction, ErrorBoundaryComponent } from "@remix-run/node";
import { json } from "@remix-run/node";
import {
  Links,
  LiveReload,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  useCatch,
  useLocation,
  useLoaderData } from
"@remix-run/react";
import { useEffect } from 'react';

import { AppContextProvider } from '~/AppContext';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { ToastContainer } from 'react-toastify';
import { app, login } from '~/services/app.server';

import toastifyStyles from 'react-toastify/dist/ReactToastify.css';
import styles from "./styles/app.css";
import materialIconsStyles from 'material-icons/iconfont/material-icons.css';
import pretendardStyles from 'pretendard/dist/web/static/pretendard.css';
import reactTooltipStyles from 'react-tooltip/dist/react-tooltip.css';
import * as gtag from "~/utils/gtags.client";

export function links() {
  return [
  { rel: "stylesheet", href: styles },
  { rel: "stylesheet", href: materialIconsStyles },
  { rel: "stylesheet", href: pretendardStyles },
  { rel: "stylesheet", href: toastifyStyles },
  { rel: "stylesheet", href: reactTooltipStyles }];

}

const appname = "AiLIVE STUDIO";
const GOOGLE_CLIENT_ID = "833531209423-skfs0jpvspvkc5s1kkinrq38r96m9vst.apps.googleusercontent.com";

export const meta: MetaFunction = () => ({
  charset: "utf-8",
  title: appname,
  viewport: "width=device-width,initial-scale=1"
});

export async function loader() {
  if (!app.currentUser) {
    await login(app);
  }
  const ret = { gaTrackingId: process.env.GA_TRACKING_ID };
  return json(ret);
}

export default function App() {
  return (
    <Document>
      <Layout>
        <Outlet />
      </Layout>
    </Document>);

}

function Document({
  children, title }: {children: React.ReactNode;title?: string;}) {

  const location = useLocation();
  //const { gaTrackingId } = useLoaderData<typeof loader>();
  const loaderData = useLoaderData<typeof loader>();
  const gaTrackingId = loaderData?.gaTrackingId || '';

  useEffect(() => {
    if (gaTrackingId?.length) {
      gtag.pageview(location.pathname, gaTrackingId);
    }
  }, [location, gaTrackingId]);

  return (
    <html lang="en">
      <head>
        {title ? <title>{title}</title> : null}
        <Meta />
        <Links />
      </head>
      <body className="select-none">
        {/* {process.env.NODE_ENV === "development" || !gaTrackingId ? null : (
           <>
             <script
               async
               src={`https://www.googletagmanager.com/gtag/js?id=${gaTrackingId}`}
             />
             <script
               async
               id="gtag-init"
               dangerouslySetInnerHTML={{
                 __html: `
                 window.dataLayer = window.dataLayer || [];
                 function gtag(){dataLayer.push(arguments);}
                 gtag('js', new Date());
                  gtag('config', '${gaTrackingId}', {
                   page_path: window.location.pathname,
                 });
                 `,
               }}
             />
           </>
          )} */}

        <GoogleOAuthProvider clientId={GOOGLE_CLIENT_ID}>
          <AppContextProvider>
            {children}
            <ScrollRestoration />
            <Scripts />
            <LiveReload />
          </AppContextProvider>
        </GoogleOAuthProvider>
      </body>
    </html>);

};

function Layout({ children }: any) {

  const style = {
    fontFamily: "Pretendard Variable, Pretendard, -apple-system, BlinkMacSystemFont, system-ui, Roboto, Helvetica Neue, Segoe UI, Apple SD Gothic Neo, Noto Sans KR, Malgun Gothic, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, sans-serif",
    lineHeight: "1"
  };

  return (
    <div className="p-0 w-screen h-screen bg-cool-gray-900 text-white flex flex-col" style={style}>
      {children}
      <ToastContainer
        toastClassName={() => "flex text-center justify-center items-center"}
        bodyClassName={() => "flex text-center justify-center items-center my-1 py-4 px-6 text-white leading-1 bg-cool-gray-800 rounded-xl shadow-xl"}
        position="bottom-center"
        autoClose={3000}
        closeButton={false}
        closeOnClick={false}
        pauseOnHover={true}
        draggable={false}
        hideProgressBar={true}
        theme={'colored'} />

    </div>);

}


export function CatchBoundary() {
  let caught = useCatch();

  let message;
  switch (caught.status) {
    case 401:
      message =
      <p>
          Not permitted to access.
        </p>;

      break;
    case 404:
      message =
      <p>Not found page.</p>;

      break;

    default:
      throw new Error(caught.data || caught.statusText);
  }

  return (
    <Document title={`${appname} - ${caught.status} ${caught.statusText}`}>
      <Layout>
        <div className="p-5">
          <h1 className="text-3xl">
            {caught.status}: {caught.statusText}
          </h1>
          <div className="py-6 px-1">
            {message}
          </div>
        </div>
      </Layout>
    </Document>);

}

export const ErrorBoundary: ErrorBoundaryComponent = ({ error }) => {
  console.error(error);
  return (
    <Document title={`${appname} - system operation failed`}>
      <Layout>
        <div className="p-5">
          <h1 className="text-3xl">
            System Operation Failed
          </h1>
          <div className="py-6 px-1">
            {`system operation failed: ${error}`}
          </div>
        </div>
      </Layout>
    </Document>);


};